html body {
  background-color: #101015;
  color: white;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
}

@media screen and (max-width: 992px) {
  html body {
    font-size: 1.2rem;
  }
}

/* FONTS */

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
}

/* HEADINGS */

h1 {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-size: 4rem!important;
}

/* GENERAL */

a {
  color: #FFF!important;
  text-decoration: none!important;
}

/* HEADER */

header .nav ul.tcp-pages li {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 20px;
  margin: 0 10px;
}

header .nav ul li a {
  color: #FFF;
  display: block; 
  text-decoration: none;
  padding: 8px 0;
}

header .nav ul.tcp-pages li a svg {
  margin-bottom: 6px;
}

header .nav ul li a:hover, header .nav ul li a:active, header .nav ul li a.active { color: #FFBB26!important; }

@media screen and (min-width: 992px) {

  header .nav ul.tcp-pages li { margin: 0 20px; }
  header .nav ul.tcp-sn { margin-left: 20px; }

}

header .nav ul.tcp-sn li a img { border: none; }

header .nav ul li a.disabled { color: #AAA!important; }

@media screen and (max-width: 992px) {
  
  header { border-bottom: 1px solid #222; }

  header img { width: 80px; }

  header .nav ul.tcp-pages {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  header .nav ul.tcp-pages li { margin: 0; font-size: 1rem; }

}

/* CARDS */

div.card {
  text-align: center;
  color: black;
}

div.card .alert { font-size: 14px; }

div.card-title { font-size: 1.6rem; }

/* Various */

.lt { font-size: 1.5rem!important; }
.lt:hover { font-size: 1.6rem!important; }

p.connect-wallet {
  color: #999;
  font-style: italic;
  letter-spacing: 1px;
}

p.client-address {
  color: #999;
  font-style: italic;
  letter-spacing: 1px;
  word-break: break-word;
}

.text-small {
  font-size: 85%;
}

@media screen and (max-width: 576px) { .text-small { font-size: 65%; } }


@media screen and (max-width: 576px) { p.contract-address, p.client-address { font-size: 1.1rem; } }

/* Admin */

table.admin-table { font-size: 12px; }
table.admin-table .break { word-break: break-word;}


@media screen and (min-width: 992px) {
  .table-responsive {
    overflow-x: hidden!important;
  }
}

p.error { color: #CD2924; }

/* FOOTER */

footer {
  padding-top: 20px;
  text-shadow: #141414 1px 0 10px;
  border-top: 1px solid #222;;
}

footer h3 {
  font-size: 24px;
}

footer .tcp-sn li a:hover {
  color: #FFBB26!important;
}

footer .tcp-sn li a.active { color: #FFBB26!important; }


footer span { white-space: pre; }